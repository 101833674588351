import React, { useState, useEffect } from 'react';
import {
  EditorState,
  Editor,
  ContentState,
  convertFromHTML,
  CompositeDecorator,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';
import { CustomContainer } from '../general/CustomContainers/CustomContainer';
import EditorToolbar from './EditorToolbar';
import { normalizeURL, stripProtocol } from './urlUtils';
import { ImagePlaceholder } from '../../assets/icons/buttons/ImagePlaceholder';

const extractLinksFromContent = (content) => {
  const linkRegex = /href=["'](https?:\/\/[^"']+)["']/g;
  const links = [];
  let match;
  while ((match = linkRegex.exec(content)) !== null) {
    links.push(normalizeURL(match[1]));
  }
  return links;
};

const linkDecorator = new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === 'LINK'
          );
        },
        callback
      );
    },
    component: ({ contentState, entityKey, children }) => {
      const { url } = contentState.getEntity(entityKey).getData();
      return (
        <a
          href={normalizeURL(url)}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          {children}
        </a>
      );
    },
  },
]);

const initializeEditorState = (html) => {
  if (!html || typeof html !== 'string') {
    return EditorState.createEmpty(linkDecorator);
  }

  try {
    if (typeof document === 'undefined') {
      return EditorState.createEmpty(linkDecorator);
    }

    const blocksFromHTML = convertFromHTML(html);
    if (!blocksFromHTML || !blocksFromHTML.contentBlocks) {
      return EditorState.createEmpty(linkDecorator);
    }

    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(contentState, linkDecorator);
  } catch (error) {
    console.error('erro ao inicializar o editorState: ', error);
    return EditorState.createEmpty(linkDecorator);
  }
};

const ImageComponentView = ({ data, isEditMode, onChangeBlock, block }) => {
  const [editorStates, setEditorStates] = useState(
    Array.isArray(data?.images)
      ? data.images.map((image) => initializeEditorState(image.description || ''))
      : []
  );

  const [lastLinks, setLastLinks] = useState(
    Array.isArray(data?.images)
      ? data.images.map((image) => image.lastLink || null)
      : []
  );

  useEffect(() => {
    if (Array.isArray(data?.images) && data.images.length > editorStates.length) {
      const newEditorStates = data.images.map((image, index) => {
        return editorStates[index] || initializeEditorState(image.description || '');
      });
      setEditorStates(newEditorStates);
    }
  }, [data.images, editorStates]);

  const handleDescriptionChange = (index, newEditorState) => {
    const updatedEditorStates = [...editorStates];
    updatedEditorStates[index] = newEditorState;
    setEditorStates(updatedEditorStates);

    const updatedImages = [...data.images];
    const rawHTML = stateToHTML(newEditorState.getCurrentContent());
    updatedImages[index].description = rawHTML;

    const extractedLinks = extractLinksFromContent(rawHTML);
    updatedImages[index].lastLink = extractedLinks.length > 0 ? extractedLinks[0] : null;

    setLastLinks(updatedImages.map((img) => img.lastLink));
    onChangeBlock(block, { ...data, images: updatedImages });
  };

  const renderLastLink = (index) => {
    const lastLink = lastLinks[index];
    if (lastLink) {
      return (
        <div className="text-sm text-gray-500 mt-2">
          Link salvo:
          <a
            href={lastLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline ml-2"
          >
            {stripProtocol(lastLink)}
          </a>
        </div>
      );
    }
    return <div className="text-sm text-gray-400 mt-2">Nenhum link salvo</div>;
  };

  return (
    <CustomContainer
      size="ensino-institucional"
      alignCenter={true}
      className="flex flex-wrap justify-center gap-x-10"
    >
      {Array.isArray(data?.images) && data.images.length > 0 ? (
        data.images.map((imageData, index) => {
          const editorState = editorStates[index] || initializeEditorState('');

          return (
            <div
              key={index}
              className="bg-white dark:bg-[#001B3D] rounded overflow-hidden w-[310px] h-auto"
            >
              {imageData.src?.data ? (
                <img
                  src={`data:image/jpg;base64,${imageData.src?.data || ''}`}
                  className="w-full h-[226px] object-cover"
                />
              ) : (
                <div className="w-full h-[226px] flex items-center justify-center bg-gray-100 dark:bg-gray-700">
                  <ImagePlaceholder color="#000000" className="dark:[&_path]:stroke-[#afafaf]" />
                </div>
              )}
              <div className="flex flex-col pt-8 pb-6 text-base text-[#0D1117] dark:text-[#F8FAFA]">
                {isEditMode ? (
                  <div className="p-2 border border-gray-300 rounded dark:border-gray-600 h-[200px] overflow-y-auto">
                    <EditorToolbar
                      index={index}
                      editorState={editorState}
                      onChange={handleDescriptionChange}
                    />
                    {renderLastLink(index)}
                    <Editor
                      editorState={editorState}
                      onChange={(newState) => handleDescriptionChange(index, newState)}
                      placeholder="Digite aqui"
                    />
                  </div>
                ) : (
                  <div
                    className="line-clamp-[8]"
                    dangerouslySetInnerHTML={{
                      __html: imageData.description || 'Sem descrição',
                    }}
                  ></div>
                )}
              </div>
            </div>
          );
        })
      ) : (
        isEditMode && <div>Nenhum card disponível</div>
      )}
    </CustomContainer>
  );
};

export default ImageComponentView;