import React from 'react';
import apucarana from './apucarana.png';
import campo_mourao from './campo_mourao.png';
import cornelio_procopio from './cornelio_procopio.png';
import curitiba from './curitiba.png';
import dois_vizinhos from './dois_vizinhos.png';
import francisco_beltrao from './francisco_beltrao.png';
import guarapuava from './guarapuava.png';
import londrina from './londrina.png';
import medianeira from './medianeira.png';
import pato_branco from './pato_branco.png';
import ponta_grossa from './ponta_grossa.png';
import santa_helena from './santa_helena.png';
import toledo from './toledo.png';
import Campi from './Campi';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { CustomContainer } from '../general';

const AllCampisView = (props) => {
  const urlHandler = (url) => (isInternalURL(url) ? flattenToAppURL(url) : url);

  return (
    <div className="container ui w-full">
      <CustomContainer
        size="ensino-institucional"
        alignCenter={true}
        className="flex flex-col justify-start items-start gap-y-10"
      >
        <h5 className="font-bold text-dsGray-900 dark:text-[#F8FAFA]">
          Acesse os 13 Campi
        </h5>
        <div className="w-full flex flex-wrap justify-start items-center">
          <Campi
            name="Apucarana"
            img={apucarana}
            link={urlHandler('/campus/apucarana/home')}
            translate="no"
          />
          <Campi
            name="Campo Mourão"
            img={campo_mourao}
            link={urlHandler('/campus/campomourao/home')}
            translate="no"
          />
          <Campi
            name="Cornélio Procópio"
            img={cornelio_procopio}
            link={urlHandler('/campus/cornelioprocopio/home')}
            translate="no"
          />
          <Campi
            name="Curitiba"
            img={curitiba}
            link={urlHandler('/campus/curitiba/home')}
            translate="no"
          />
          <Campi
            name="Dois Vizinhos"
            img={dois_vizinhos}
            link={urlHandler('/campus/doisvizinhos/home')}
            translate="no"
          />
          <Campi
            name="Francisco Beltrão"
            img={francisco_beltrao}
            link={urlHandler('/campus/franciscobeltrao/home')}
            translate="no"
          />
          <Campi
            name="Guarapuava"
            img={guarapuava}
            link={urlHandler('/campus/guarapuava/home')}
            translate="no"
          />
          <Campi
            name="Londrina"
            img={londrina}
            link={urlHandler('/campus/londrina/home')}
            translate="no"
          />
          <Campi
            name="Medianeira"
            img={medianeira}
            link={urlHandler('/campus/medianeira/home')}
            translate="no"
          />
          <Campi
            name="Pato Branco"
            img={pato_branco}
            link={urlHandler('/campus/patobranco/home')}
            translate="no"
          />
          <Campi
            name="Ponta Grossa"
            img={ponta_grossa}
            link={urlHandler('/campus/pontagrossa/home')}
            translate="no"
          />
          <Campi
            name="Santa Helena"
            img={santa_helena}
            link={urlHandler('/campus/santahelena/home')}
            translate="no"
          />
          <Campi
            name="Toledo"
            img={toledo}
            link={urlHandler('/campus/toledo/home')}
            translate="no"
          />
        </div>
      </CustomContainer>
    </div>
  );
};

export default AllCampisView;
