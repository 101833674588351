import React from 'react';

const Campi = ({ name, img, link, translate = 'yes' }) => {
  return (
    <a
      href={link}
      className="bg-[#001326] group relative max-md:w-2/6 w-[120px] h-[210px] cursor-pointer overflow-hidden"
    >
      <img
        src={img}
        className="absolute w-full h-full object-cover opacity-70 group-hover:opacity-100 group-hover:scale-110 transition duration-300"
        alt={name}
      />
      <div className="absolute w-full bg-black/60 group-hover:bg-[#FFBE00]/70 transition duration-300 h-[60px] bottom-0 flex justify-center items-center p-3">
        <p translate={translate} className="font-bold text-white group-hover:text-black text-[15px] text-center transition duration-300">{name}</p>
      </div>
    </a>
  );
};

export default Campi;
