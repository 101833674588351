//@ts-check
import React from 'react';
//@ts-ignore
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { IconsName } from '../../../assets/icons/buttons';
import { twJoin, twMerge } from 'tailwind-merge';
import { CustomContainer } from '../../general';
import { NavLink } from 'react-router-dom';
import { fixLink } from './fixLink';

const CheckInternal = ({ className, newTab, href, children }) => {
  if (isInternalURL(href) && !href.includes("#")) {
    return (
      <NavLink
        className={className}
        to={flattenToAppURL(href)}
        target={twJoin(newTab ? '_blank' : '_self')}
        rel="noreferrer"
      >
        {children}
      </NavLink>
    );
  } else {
    return (
      <a
        target={twJoin(newTab ? '_blank' : '_self')}
        className={className}
        href={fixLink(href)}
        rel="noreferrer"
      >
        {children}
      </a>
    );
  }
};

const Button = ({ title, icon, color, size, href, newTab, isEditMode }) => {
  const IconComponente = IconsName[icon];

  const iconColor = {
    black: '#000000',
    blue: '#005DB7',
  };

  return (
    <CheckInternal
      className={twJoin(
        'group flex flex-col sm:flex-row items-center gap-2 sm:gap-6 w-full max-w-[300px]', 
        (isEditMode || href === '#') && 'cursor-default pointer-events-none'
      )}
      newTab={newTab}
      href={href}
    >
      <IconComponente
        color={iconColor[color]}
        className={twJoin(
          'w-[32px] h-[32px] shrink-0 transition duration-500', 
          size === '48px' && 'md:w-[48px] md:h-[48px]', 
          'group-hover:text-[#FCBC00] dark:group-hover:text-[#FCBC00]' 
        )}
        changeColorOnHover={true}
      />
      {title && (
        <span
          className={twMerge(
            'dark:text-[#F8FAFA] text-center sm:text-start font-dsBody break-words transition duration-75', 
            'group-hover:text-[#FCBC00] dark:group-hover:text-[#FCBC00]'
          )}
        >
          {title}
        </span>
      )}
    </CheckInternal>
  );
};

export const GridButtonsView = (props) => {
  const { data, isEditMode } = props;

  return (
    <CustomContainer
      size="pesquisa-extensao"
      alignCenter={true}
      className="px-4 sm:px-0 grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-y-6 gap-x-8 md:gap-x-16 justify-items-center items-center"
    >
      {data.buttons?.map((btn, index) => {
        return (
          <Button
            key={index}
            title={btn.title}
            color={data.color ?? 'blue'}
            size={data.size ?? '48px'}
            icon={btn.icon ?? 'Default'}
            href={btn.link ?? '#'}
            newTab={btn.newTab ?? false}
            isEditMode={isEditMode}
          />
        );
      })}
    </CustomContainer>
  );
};
